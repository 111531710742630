// i18n
import 'locales/i18n';

import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
// toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// providers
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import { AuthProvider } from 'providers/FirebaseProvider';
// routes
import { router } from 'Routes';
import { APIProvider } from 'providers/APIProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <APIProvider>
        <AuthProvider>
          <SettingsPanelProvider>
            <ChatWidgetProvider>
              <BreakpointsProvider>
                <ToastContainer />
                <div>
                  <RouterProvider router={router} />
                </div>
              </BreakpointsProvider>
            </ChatWidgetProvider>
          </SettingsPanelProvider>
        </AuthProvider>
      </APIProvider>
    </AppProvider>
  </React.StrictMode>
);
