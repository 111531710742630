import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { capitalize } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { Nav, Navbar } from 'react-bootstrap';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';
import NavbarVerticalMenu from './NavbarVerticalMenu';
// hooks
import { useNavigationRoutes } from 'hooks/useNavigationRoutes';

const NavbarVerical = () => {
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();
  const { breakpoints } = useBreakpoints();

  const routes = useNavigationRoutes();

  const handleClickRefresh = () => {
    if (window.location.reload) {
      // Forcing the reload to skip the cache
      window.location.reload();
    } else {
      // Fallback for browsers that don't support the first argument in reload
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }
  };
  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className={classNames('navbar-vertical', {
          'navbar-darker': navbarVerticalAppearance === 'darker'
        })}
        expand="lg"
        variant=""
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content">
            <Nav className="flex-column" as="ul" id="navbarVerticalNav">
              {routes
                .filter(r => !r.labelDisabled)
                .map(route => (
                  <Nav.Item key={route.label}>
                    <div>
                      <p className="navbar-vertical-label">
                        {capitalize(route.label)}
                      </p>
                      <hr className="navbar-vertical-line" />
                    </div>
                    <NavbarVerticalMenu level={1} routes={route.pages} />
                  </Nav.Item>
                ))}
            </Nav>

            {routes.length <= 0 && (
              <div className="vstack gap-2 mx-auto mt-4">
                <p className="fw-400 fs-10 text-400 text-center">
                  Tu ne vois aucune catégorie ? Tu peux rafraichir en cliquant
                  ici
                </p>
                <Button
                  size="sm"
                  variant="soft-secondary"
                  className="mx-auto"
                  onClick={handleClickRefresh}
                >
                  Rafraichir
                </Button>
              </div>
            )}

            {navbarPosition === 'combo' && breakpoints.down('lg') && (
              <div className="move-container">
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr" />
                </div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>
        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <div>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2">Réduire</span>
              </div>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVerical;
